import React, { useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import GlobalStyles from './components/GlobalStyles';

import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createTheme } from './theme';
import { Helmet } from 'react-helmet-async';

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  // components: {
  //   MuiTypography: {
  //     variants: [
  //       {
  //         props: { variant: 'lineDataTop' },
  //         style: {
  //           color:"textPrimary",
  //           fontFamily:"Roboto",
  //           fontSize:'28px',
  //           textAlign:"center"
  //         }
  //       },
  //       {
  //         props: { variant: 'lineDataBottom' },
  //         style: {
  //           color:"textPrimary",
  //           fontFamily:"Roboto",
  //           fontSize:'13px',
  //           textAlign:"center"
  //         }
  //       },
  //       {
  //         props: { variant: 'bigTitle' },
  //         style: {
  //           color:"textPrimary",
  //           fontFamily:"Roboto",
  //           fontWeight:'bold',
  //           fontSize:'35px',
  //           textAlign:"center"
  //         }
  //       },{
  //         props: { variant: 'subTitle' },
  //         style: {
  //           color:"textPrimary",
  //           fontFamily:"Roboto",
  //           fontWeight:'bold',
  //           fontSize:'20px',
  //           textAlign:"center"
  //         }
  //       },{
  //         props: { variant: 'title' },
  //         style: {
  //           color:"textPrimary",
  //           fontFamily:"Roboto",
  //           fontWeight:'bold',
  //           fontSize:'15px',
  //           textAlign:"center"
  //         }
  //       },{
  //         props: { variant: 'data' },
  //         style: {
  //           color:"textPrimary",
  //           fontFamily:"Roboto",
  //           fontSize:'15px',
  //           textAlign:"center"
  //         }
  //       }
  //     ]
  //   },
  // }

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet" />

      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            dense
            maxSnack={3}
          >
            <GlobalStyles />
            {auth.isInitialized ? content : <SplashScreen />}
          </SnackbarProvider>

        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
