import React from 'react';
import PropTypes from 'prop-types';
import { matchPath,useLocation } from 'react-router-dom';
import { List,Box } from '@mui/material';
import NavItem from './NavItem';

const renderNavItems = ({ depth = 0, items, pathname,onClick }) => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth,
        onClick
      }), []
    )}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth,onClick }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false;
  console.log("reduceChildRoutes:",exactMatch,pathname,item.path)
  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        onClick={onClick}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        onClick={onClick}
      />
    );
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title,icon,onClick, ...other } = props;
  const location = useLocation()
  const exactMatch = pathname ? !!matchPath({
    path: pathname,
    end: true
  }, location.pathname) : false;
  return (
    <List
    disablePadding
        {...other}
        >
        <NavItem
        active={exactMatch}
        depth={0}
        icon={icon}
        path={items.length === 0 ? pathname : ""}
        title={title}
        onClick={onClick}
      />
      <Box
      sx={{ml:0}}>
     {renderNavItems({
        items,
        pathname,
        onClick
      })}
      </Box>
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default NavSection;
