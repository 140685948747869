import React,{ Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';

import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
// import UserManage from './views/User/UserManage';

import { useNavigate, useLocation } from 'react-router-dom';
import DashboardLayoutForPublic from './views/PublicView/DashboardLayoutForPublic';



function Loadable(Component) {
  const InjectedNavigate = function (props) {
    const navigate = useNavigate(props);
    const location = useLocation(props);
    return (<Suspense fallback={<LoadingScreen />}>
      <Component {...props} navigate={navigate} location={location} />
    </Suspense>)
  };
  return InjectedNavigate;
}


const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Profile = Loadable(lazy(() => import('./views/User/Profile')));

const CompanyDetails = Loadable(lazy(() => import('./views/Company/CompanyDetails')));
const CompanyManage = Loadable(lazy(() => import('./views/Company/CompanyManage')));

const UserList = Loadable(lazy(() => import('./views/User/UserList')));
const UserManage = Loadable(lazy(() => import('./views/User/UserManage')));

const EndUserList = Loadable(lazy(() => import('./views/EndUser/UserList')));
const EndUserManage = Loadable(lazy(() => import('./views/EndUser/UserManage')));

const CustomerList = Loadable(lazy(() => import('./views/Customer/CustomerList')));
const CustomerManage = Loadable(lazy(() => import('./views/Customer/CustomerManage')));

const LoginLogs = Loadable(lazy(() => import('./views/LoginLogs/LoginLogs')));

const CompanySiteManage = Loadable(lazy(() => import('./views/Company/SitesAndZones/CompanySiteManage')));
const CompanySiteDetails = Loadable(lazy(() => import('./views/Company/SitesAndZones/CompanySiteDetails')));

const PasswordSecurityForm = Loadable(lazy(() => import('./views/Security/PasswordSecurityForm')));

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const SiteShiftManage = Loadable(lazy(() => import('./views/Company/SitesAndZones/Shifts/SiteShiftManage')));

const InspectionList = Loadable(lazy(() => import("./views/Inspection/InspectionList")));
const InspectionManage = Loadable(lazy(() => import("./views/Inspection/InspectionManage")));
const InspectionDetails = Loadable(lazy(() => import("./views/Inspection/InspectionDetails")));
const CustomerDetails = Loadable(lazy(() => import("./views/Customer/CustomerDetails")));
const UserDetails = Loadable(lazy(() => import("./views/User/UserDetails")));
const EndUserDetails = Loadable(lazy(() => import("./views/EndUser/UserDetails")));
const InspectionView = Loadable(lazy(() => import("./views/PublicView/InspectionView")));
const EmailView = Loadable(lazy(() => import("./views/PublicView/EmailView")));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      }
    ]
  },
  {
    path: 'inspectionView',
    element: (
      
        <DashboardLayoutForPublic />
      
    ),
    children: [
      {
        path: '/inspectionView/:id',
        element: <InspectionView />
      }
    ]
  },
  {
    path: 'emailView',
    
    children: [
      {
        path: '/emailView',
        // element: <Overview />,
        // element: <FloorView />
        element: <EmailView />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/dashboard',
        // element: <Overview />,
        // element: <FloorView />
        element: <InspectionList />
      },
      {
        path: '/dashboard/profile',
        element: <Profile />
      },
      {
        path: '/dashboard/manage',
        element: <InspectionManage />
      },
      {
        path: '/dashboard/details',
        element: <InspectionDetails />
      }
    ]
  },
  {
    path: 'users',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/users',
        element: <UserList />
      },
      {
        path: '/users/manage',
        element: <UserManage />
      },
      {
        path: '/users/details',
        element: <UserDetails />
      }
    ]
  },
  {
    path: 'endUsers',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/endUsers',
        element: <EndUserList />
      },
      {
        path: '/endUsers/manage',
        element: <EndUserManage />
      },
      {
        path: '/endUsers/details',
        element: <EndUserDetails />
      }
    ]
  },

  {
    path: 'customers',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/customers',
        element: <CustomerList />
      },
      {
        path: '/customers/manage',
        element: <CustomerManage />
      },
      {
        path: '/customers/details',
        element: <CustomerDetails />
      }
    ]
  },

  {
    path: 'loginlogs',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/loginlogs',
        element: <LoginLogs />
      },
    ]
  },
  {
    path: 'passwordsecurity',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/passwordsecurity',
        element: <PasswordSecurityForm />
      },
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        // element: <FloorView />
        element: <InspectionList />
      },
    ]
  },

  {
    path: 'company',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/company',
        element: <CompanyDetails />
      },
      {
        path: '/company/manage',
        element: <CompanyManage />
      },
      {
        path: '/company/sites/details',
        element: <CompanySiteDetails />
      },
      {
        path: '/company/sites/manage',
        element: <CompanySiteManage />
      },
      
      {
        path: '/company/sites/shift/manage',
        element: <SiteShiftManage />
      },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '/',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
