import React, { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography
} from '@mui/material';
import useAuth from '../../hooks/useAuth';
import Menu from '../../images/menu/menu.png';
import NavSection from '../NavSection';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ChevronDownIcon from '../../icons/ChevronDown';


const AccountPopover = (props) => {
 
  const location = useLocation();
  const anchorRef = useRef(null);
  const { user, logout, changePassword } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  };
  let arrayAccess = [
    {
      title: 'Profile',
      roles: ["Admin", "SuperAdmin", "Standard", "EndUser"],
      pathname: '/dashboard/profile',
      items: []
    },
    {
      title: 'Change Password',
      roles: ["Admin", "SuperAdmin", "Standard", "EndUser"],
      pathname: null,
      items: [],
      onClick: changePassword
    },
    {
      title: 'Users',
      roles: ["Admin", "SuperAdmin", "Standard"],
      pathname: '/users',
      items: []
    },
    {
      title: 'End Users',
      roles: ["Admin", "SuperAdmin", "Standard"],
      pathname: '/endUsers',
      items: []
    },
    {
      title: 'Customers',
      roles: ["Admin", "SuperAdmin"],
      pathname: '/customers',
      items: []
    }
    // ,
    // {
    //   title: 'Company Details',
    //   roles: ["Admin", "SuperAdmin", "Standard"],
    //   pathname: '/company',
    //   items: [],

    // }
  ]
  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <img
          src={Menu}
          alt="Flexvertical Menu"
          height="13"
          width="21"
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            sx={{
              color: '#262B40',
              fontSize: '15px',
              fontFamily: 'roboto',
            }}
          >
            <b>{localStorage.getItem("fullName")}</b>
          </Typography>
          {/* <Typography
            sx={{
              color: '#262B40',
              fontSize: '15px',
              fontFamily: 'roboto',
              my:1,
            }}
          >
            Flexvertical : {localStorage.getItem("role")}
          </Typography> */}
          
        </Box>
        <Divider />
        <Box sx={{ mt: 1, p: 2 }}>
          {arrayAccess.map((section) => {
            if (section.roles.indexOf(localStorage.getItem("role")) == -1) {
              return ("")
            } else {
              return (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  onClick={section.onClick}
                  sx={{
                    '& + &': {
                      mt: 0
                    }
                  }}
                  {...section}
                />)
            }
          })}

        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            sx={{ border: "1px solid #51A2F7", borderRadius: "10px" }}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
