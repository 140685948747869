import React,{ useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';
import Auth from '../pages/authentication/Auth';
import Reset from '../pages/authentication/Reset';
import ForgotReset from '../pages/authentication/ForgotReset';
import Forgot from '../pages/authentication/Forgot';

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  console.log("INITIAL AuthGuard:isAuthorized", auth.isAuthorized, "isAuthenticated", auth.isAuthenticated,"FORM TYPE",auth.formType)
  // if (!(localStorage.getItem("isLogin") != "" && localStorage.getItem("isLogin") == "true")) {

  switch (auth.formType) {
    case "LOGIN":
      if (location.pathname !== requestedLocation) {
        setRequestedLocation(location.pathname);
      }
      return <Login />;
    case "AUTH":
      if (location.pathname !== requestedLocation) {
        setRequestedLocation(location.pathname);
      }
      return <Auth />;
    case "FORGOT":
      if (location.pathname !== requestedLocation) {
        setRequestedLocation(location.pathname);
      }
      return <Forgot />;
    case "RESET":
      if (location.pathname !== requestedLocation) {
        setRequestedLocation(location.pathname);
      }
      return <Reset regex={auth.regex} />;
    // case "FORGOT":
    //   return <ForgotPassForm />;
    case "FORGOTRESET":
    case "CHANGEPASSWORD":
      if (location.pathname !== requestedLocation) {
        setRequestedLocation(location.pathname);
      }
      return <ForgotReset regex={auth.regex} />;
    case "":
      if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
      }
      return <>{children}</>;
    default:
      if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
      }
      return <>{children}</>;
  }
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
