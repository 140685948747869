import React, { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Grid } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';

import Logo from '../../images/logo.png';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
    borderBottom: `2px solid #D5D5D5`,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));


const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  

  

  useEffect(() => {
    
  },
    []);


  return (
    
    <DashboardNavbarRoot {...other} sx={{ minHeight: 60, maxHeight: 60, backgroundColor: '#F8F8FA' }}>
      <Toolbar sx={{ minHeight: 60, maxHeight: 60 }}>
  
        <Grid
          container
          direction="row"
          justifyContent={"flex-start"}
          alignContent={"center"}
        >
          <Grid
            width={"290px"}
            item
            alignItems={"center"}
          >
            
              <img
              style={{
                
              }}
                src={Logo}
                alt="Premier Logo"
                height="45"
              />
            

          </Grid>
        </Grid>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 2 }}>
        {/* {(localStorage.getItem("isLogin") == "true") && (<AccountPopover />)} */}
        
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
