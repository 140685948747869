import React, { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Grid } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import AccountPopover from './AccountPopover';
import Logo from '../../images/logo.png';
import axios from '../../lib/axios';
import AppMenu from './AppMenu';

const sections = [

  {
    title: 'Site Performance',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/SitePerformance',
    items: [],

  },
  {
    title: 'Machine Assets',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/machines',
    items: [],

  },
  {
    title: 'Operations Planning',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/calender',
    items: [],

  },
  {
    title: 'Reporting',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/reports',
    items: [],

  },
  {
    title: 'Alert Triggers',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/notifications/alertTriggers',
    items: [],

  },
  {
    title: '',
    roles: ["Admin", "SuperAdmin", "Standard"],
    items: [],
    path: '#'
  },
  {
    title: 'Work Orders',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/workorders',
    items: [],

  },
  {
    title: 'Maintenance Profiles',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/maintenance',
    items: [],

  },
  {
    title: 'Downtime Codes',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/company/downtimes',
    items: [],

  },
  {
    title: '',
    roles: ["Admin", "SuperAdmin", "Standard"],
    items: [],
    path: '#',
  },
  {
    title: 'Users',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/users',
    items: [],

  },
  {
    title: 'Contractors',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/company/contractors',
    items: [],

  },
  {
    title: 'Site Configuration',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/company/sites/details',
    items: [],

  },
  {
    title: 'Company Details',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/company',
    items: [],

  },
  {
    title: 'IoT Gateways',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/gateways',
    items: [],

  },
  {
    title: 'Sensor Notifications',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '/notifications',
    items: [],

  },
  {
    title: '',
    roles: ["Admin", "SuperAdmin", "Standard"],
    items: [],
    path: '#'
  },
  {
    title: '',
    roles: ["Admin", "SuperAdmin", "Standard"],
    items: [],
    path: '#'
  },
  {
    title: 'support@flexvertical.com',
    roles: ["Admin", "SuperAdmin", "Standard"],
    path: '#',
    items: [],

  }
];

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
    borderBottom: `2px solid #D5D5D5`,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));


const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const [sites, setSites] = useState([]);

  const location = useLocation()
  const naviate = useNavigate()



  useEffect(() => {
    console.log("IsLogin",localStorage.getItem("isLogin"))
  },
    []);


  return (
    
    <DashboardNavbarRoot {...other} sx={{ minHeight: 60, maxHeight: 60, backgroundColor: '#F8F8FA' }}>
      <Toolbar sx={{ minHeight: 60, maxHeight: 60 }}>
        {/* 
        <IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
            <MenuIcon fontSize="small" />
          </IconButton> */}


        {/* <Box
          sx={{
            flexGrow: 2,
            ml: 2
          }}
        /> */}
        <Grid
          container
          direction="row"
          justifyContent={"flex-start"}
          alignContent={"center"}
        >
          <Grid
            width={"290px"}
            item
            alignItems={"center"}
          >
            <RouterLink to="/">
              <img
              style={{
                
              }}
                src={Logo}
                alt="Flexvertical Logo"
                height="45"
              />
            </RouterLink>

          </Grid>
        </Grid>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 2 }}>
        {/* {(localStorage.getItem("isLogin") == "true") && (<AccountPopover />)} */}
        <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
